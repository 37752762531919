import { useLocation } from 'react-router-dom';
import CustomLink from '../components/NavigationBar/CustomLink';
import { LuHome } from 'react-icons/lu';

export const Breadcrumbs = () => {
  const location = useLocation();

  let currentLink = '';
  const crumbs = location.pathname
    .split('/')
    .filter((crumbs) => crumbs !== '')
    .map((crumb, index, array) => {
      if (crumb === 'post' || crumb === 'edit') {
        currentLink += '/Blog';
      } else {
        currentLink += `/${crumb}`;
      }

      return (
        <div className="crumb" key={crumb}>
          {index === 0 && (
            <CustomLink to="/">
              <LuHome />
            </CustomLink>
          )}
          <CustomLink to={currentLink} className={index === array.length - 1 ? 'last-crumb' : ''}>
            {crumb === 'post' || crumb === 'edit' ? 'Blog' : crumb}
          </CustomLink>
        </div>
      );
    });
  return <div className="breadcrumbs">{crumbs}</div>;
};
