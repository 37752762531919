import React from 'react';
import styles from './PhotoText.module.css';
const PhotoText = (props) => {
  return (
    <div className={styles.container}>
      <img className={styles.photo} src={props.img} loading="lazy" alt={props.alt} />
      <p className={styles.paragraph}>{props.children}</p>
    </div>
  );
};

export default PhotoText;
