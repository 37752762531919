import React, { useContext } from 'react';
import { UserContext } from '../../../UserContext';
import CustomLink from '../../../components/NavigationBar/CustomLink';

export const Header = () => {
  const { setUserInfo, userInfo } = useContext(UserContext);

  // useEffect(() => {
  //   fetch('`${process.env.REACT_APP_BACKENDURL}/profile', {
  //     credentials: 'include',
  //   }).then((response) => {
  //     response.json().then((userInfo) => {
  //       setUserInfo(userInfo);
  //       // setUsername(userInfo.username);
  //     });
  //   });
  // }, [setUserInfo]);

  const logout = () => {
    fetch(`${process.env.REACT_APP_BACKENDURL}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
  };

  const username = userInfo?.username;

  return (
    <header>
      <CustomLink to="/" className="logo">
        SmartPush
      </CustomLink>
      <nav>
        {username && (
          <>
            <CustomLink to="/blog">
              <span>Ahoj, {username}</span>
            </CustomLink>
            <CustomLink to="/create">Vytvořit nový post</CustomLink>
            <a href="/" onClick={logout}>
              Odhlásit se
            </a>
          </>
        )}
        {!username && (
          <>
            {/* <CustomLink to="/login">Login</CustomLink>
            <CustomLink to="/register">Register</CustomLink> */}
          </>
        )}
      </nav>
    </header>
  );
};
