import React, { useEffect, useState } from 'react';
import { Post } from './blog/container/Post';

const IndexPage = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKENDURL}/post`).then((response) => {
      response
        .json()
        .then((posts) => {
          setPosts(posts);
        })
        .catch((error) => {
          console.log('Error fetching data:' + error);
        });
    });
  }, []);
  // console.log(posts);
  return <div className="display-card">{posts.length > 0 && posts.map((post) => <Post key={post._id} {...post} />)}</div>;
};

export default IndexPage;
