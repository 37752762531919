import React from 'react';
import styles from './Post.module.css';
import PostText from './PostText';
// import { handleScrollOnId } from '../../hooks/handleScrollOnId';
const Post = (props) => {
  return (
    <>
      {props.photo === 'left' ? (
        <div className={styles.content}>
          <img className={styles.imageSizeMedium} loading="lazy" src={props.img} alt={props.alt} />
          <div>
            <PostText>{props.title}</PostText>
            <p className={styles.textMedium}>{props.children}</p>
            <div className={styles.display}></div>
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div>
            <PostText>{props.title}</PostText>
            <div className={styles.textMedium}>{props.children}</div>
            <div className={styles.display}></div>
          </div>
          <img className={styles.imageSizeMedium} loading="lazy" src={props.img} alt={props.alt} />
        </div>
      )}
    </>
  );
};

export default Post;
