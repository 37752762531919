import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Background from './Background';
import ContactBar from '../ContactBar/ContactBar';
const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <Background />
      <ContactBar />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
