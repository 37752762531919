import React from 'react';
import NFT from '../../img/NFT.webp';
import DWC from '../../img/DWC.webp';
import Wicking from '../../img/Wicking.webp'; //TODO: brat fotky primo z DTB z obou souboru
import PhotoText from './PhotoText';
import EbbandFlow from '../../img/EbbandFlow.webp';
import Aeroponics from '../../img/Aeroponics.webp';
import Drip from '../../img/Drip.webp';
import styles from './PhotoText.module.css';
const RawPhotoText = () => {
  return (
    <div className={styles.container_flex}>
      <PhotoText img={NFT} alt="NFT">
        Systém NFT (Nutrien Film Technique) je způsob pěstování rostlin bez použití půdy, kde rostliny jsou upevněny na trubkách, které dodávají vodu a živiny, čímž umožňují kontrolovat růst rostlin a maximalizovat jejich produktivitu.
      </PhotoText>
      <PhotoText img={DWC} alt="DWC">
        Systém DWC (Deep Water Culture) je způsob pěstování rostlin bez použití půdy, kde rostliny jsou umístěny v nádrži s nutriční vodou, ve které se rostliny kořeny ponoří,čímž se dosáhne vysoké účinnosti a efektivity v pěstování rostlin.
      </PhotoText>
      <PhotoText img={Wicking} alt="Wicking">
        Systém Wicking je jednoduchý způsob pěstování rostlin bez použití půdy, kde rostliny stojí v substrátu, který je navlhče zásobníkem s nutriční vodou, který je propojen s půdou skrze wick (šňůru), čímž se zajistí rostlinám pravidelný přísun vody a živin.
      </PhotoText>
      <PhotoText img={EbbandFlow} alt="EbbAndFlow">
        Ebb And Flow je zavlažovací systém, kde se rostliny střídavě zalévají a vysušují, aby se dosáhlo optimálního přísunu živin a kyslíku.
      </PhotoText>
      <PhotoText img={Aeroponics} alt="Aeroponics">
        Aeroponics je způsob pěstování rostlin bez použití půdy, kde se rostliny vystavují rozptýlenému mléčnému oparu živin a vody.
      </PhotoText>
      <PhotoText img={Drip} alt="Drip">
        Drip System je zavlažovací systém, kde se živiny a voda postupně kapají na kořeny rostlin, aby se dosáhlo řízeného přísunu vody a živin.
      </PhotoText>
    </div>
  );
};

export default RawPhotoText;
