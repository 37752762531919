import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from './ContactForm.module.css';
import { IoWarningOutline } from 'react-icons/io5';
const ContactForm = () => {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState({
    userName: '',
    email: '',
    message: '',
  });
  const form = useRef();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    let isValid = true;
    let errors = { userName: '', email: '', message: '' };

    if (userName.length <= 4) {
      errors.userName = 'Jméno musí být větší jak 4 znaky.';
      isValid = false;
    }

    if (!validateEmail(email)) {
      errors.email = 'Zatejte platnou emailovou adresu.';
      isValid = false;
    }

    if (message.length <= 10) {
      errors.message = 'Zpráva musí být, alespoň 10 znaků dlouhá.';
      isValid = false;
    }

    setValidationMessage(errors);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    emailjs.sendForm(process.env.REACT_APP_SERVICE_EMAIL, process.env.REACT_APP_TEMPLATE_EMAIL, form.current, process.env.REACT_APP_ID_EMAIL).then(
      (result) => {
        setEmail('');
        setMessage('');
        setUserName('');
      },
      (error) => {
        console.log(error.text);
      },
    );
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
    if (validationMessage.userName) setValidationMessage({ ...validationMessage, userName: '' });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validationMessage.email) setValidationMessage({ ...validationMessage, email: '' });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (validationMessage.message) setValidationMessage({ ...validationMessage, message: '' });
  };

  return (
    <div className={styles.borderFooter}>
      <form ref={form} onSubmit={sendEmail}>
        <label>Jméno</label>
        <input type="text" value={userName} onChange={handleUserNameChange} name="user_name" />
        {validationMessage.userName && (
          <div className={styles.error}>
            <IoWarningOutline color="red" />
            {validationMessage.userName}
          </div>
        )}
        <label>Email</label>
        <input type="email" value={email} onChange={handleEmailChange} name="user_email" />
        {validationMessage.email && (
          <div className={styles.error}>
            <IoWarningOutline color="red" />
            {validationMessage.email}
          </div>
        )}
        <label>Zpráva</label>
        <textarea value={message} onChange={handleMessageChange} name="message" />
        {validationMessage.message && (
          <div className={styles.error}>
            <IoWarningOutline color="red" />
            {validationMessage.message}
          </div>
        )}
        <input type="submit" value="Odeslat" />
      </form>
    </div>
  );
};

export default ContactForm;
