import CustomLink from '../../../components/NavigationBar/CustomLink';

export const Post = ({ urlTitle, title, summary, cover, content, createdAt, author, minutes }) => {
  const date = new Date(createdAt);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;

  const summaryShorter = `${summary.slice(0, 160)}...`;

  return (
    <div className="post">
      <div className="image">
        <CustomLink to={`/post/${urlTitle}`}>
          <img src={`https://smartpush-aws-s3-bucket.s3.eu-central-1.amazonaws.com/${cover}`} alt={urlTitle} />
        </CustomLink>
      </div>
      <div className="info">
        <time>{formattedDate}</time>
        {minutes && <span>{Math.floor(minutes)} min</span>}
      </div>
      <div className="texts">
        <CustomLink to={`/post/${urlTitle}`}>
          <h2>{title}</h2>
        </CustomLink>
        <p className="summary">{summaryShorter}</p>
      </div>
    </div>
  );
};
