import React from 'react';
import { useResolvedPath, useMatch } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './NavigationBar.module.css';

const CustomLink = ({ to, children, ...props }) => {
  const resolvePath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvePath.pathname, end: true });

  return (
    <li className={isActive ? styles.active : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
};

export default CustomLink;
