import HomePage from './pages/home/HomePage.jsx';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import Blog from './pages/blog/Blog.jsx';
import Layout from './components/Layout.jsx';
import LoginPage from './pages/LoginPage.jsx';
import RegisterPage from './pages/RegisterPage.jsx';
import { UserContextProvider } from './UserContext.js';
import React from 'react';
import CreatePost from './pages/CreatePost.jsx';
import PostPage from './pages/PostPage.jsx';
import EditPage from './pages/EditPage.jsx';

function App() {
  return (
    <UserContextProvider>
      <div className={styles.content}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog/*" element={<Blog />}></Route>
          {/*Layout for users */}
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/create" element={<CreatePost />} />
            <Route path="/post/:id" element={<PostPage />} />
            <Route path="/edit/:urlTitle" element={<EditPage />} />
          </Route>
        </Routes>
      </div>
    </UserContextProvider>
  );
}

export default App;
