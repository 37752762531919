import React from 'react';
import './blog.css';
// import { Post } from './container/Post';
// import { Header } from './container/Header';
import { Route, Routes } from 'react-router-dom';
import Layout from '../../components/Layout';
import IndexPage from '../IndexPage';

const Blog = () => {
  return (
    <Routes>
      {/*Layout for BLOG */}
      <Route path="/" element={<Layout />}>
        <Route index element={<IndexPage />}></Route>
      </Route>
    </Routes>
  );
};

export default Blog;
