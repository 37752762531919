import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../UserContext';
import { Navigate } from 'react-router-dom';
import Editor from '../components/Editor';
import 'react-quill/dist/quill.snow.css';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const { setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKENDURL}/profile`, {
      credentials: 'include',
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, [setUserInfo]);

  const username = userInfo?.username;
  const createNewPost = async (e) => {
    const data = new FormData();
    data.set('title', title);
    data.set('summary', summary);
    data.set('content', content);
    data.set('file', files[0]);
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_BACKENDURL}/post`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    });
    const json = await response.json();
    if (response.ok) {
      setEmptyFields([]);
      setError(null);
      setRedirect(true);
    } else {
      setError(json.error);
      setEmptyFields(Object.values(json.emptyFields).join(', '));
    }
  };

  if (redirect) {
    return <Navigate to={'/blog'} />;
  }
  return username ? (
    <form onSubmit={createNewPost}>
      <input type="title" placeholder={'Title'} value={title} style={emptyFields.includes('title') ? { borderColor: 'red' } : {}} onChange={(e) => setTitle(e.target.value)} />
      <input type="summary" placeholder="Summary" value={summary} style={emptyFields.includes('summary') ? { borderColor: 'red' } : {}} onChange={(e) => setSummary(e.target.value)} />
      <input type="file" style={emptyFields.includes('file') ? { borderColor: 'red' } : {}} onChange={(e) => setFiles(e.target.files)} />
      <Editor onChange={setContent} value={content} style={emptyFields.includes('content') ? { backgroundColor: 'rgb(230,130,130)' } : {}} />
      {error && (
        <div style={{ color: 'red', fontSize: '2rem' }}>
          {error}: {emptyFields}
        </div>
      )}
      <button style={{ marginTop: '5px' }}>Create post</button>
    </form>
  ) : (
    <div>Je potřeba se prvně přihlásit ❤️ /login</div>
  );
};

export default CreatePost;
