import React from 'react';
import backgroundPhoto from '../../img/hydroponics-photo.webp';

import styles from './NavigationBar.module.css';

const Background = () => {
  return <img src={backgroundPhoto} alt="Hydroponie background" className={styles.banner} id="homeSection"></img>;
};

export default Background;
