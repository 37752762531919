import React from 'react';
import ArticleText from '../Post/PostText';
import styles from './Services.module.css';
import StrongText from '../Post/StrongText';
const Services = () => {
  return (
    <div id="serviceSection">
      <div className={styles.about}>
        <div className={styles.content}>
          {/* <div className={styles.title}>Služby</div> */}
          <ArticleText>Služby</ArticleText>
          <p>
            Smartpush nabízí konzultaci v oboru <StrongText>hydroponie</StrongText> a <StrongText>aquaponie</StrongText>. Sestavení hydroponických a aquaponických systémů u Vás doma nebo ve skleníku.
          </p>
          <p>
            Automatizaci skleníku od jednoduchého spínání osvětlení až k predikcím dat a ovládání celého systému. Za pomocí nejmodernějších technologií <StrongText>IoT</StrongText>, <StrongText>Bigdata</StrongText> a <StrongText>Cloudového uložiště</StrongText>.
          </p>
          <p>
            Výsledkem je například <StrongText>úspora energie</StrongText> ročně v průměru až o <StrongText>20%-30%</StrongText> jen na osvětlení a optimalizace tepelných ztrát přímo pro vaše prostředí. Výstupem pro váše IoT řešení je automatizované řešení a <StrongText>frontendová aplikace</StrongText> pro přehledné zobrazení dat.
          </p>
          <p>
            Pokud jste zemědělec v oboru hydroponie nebo aquaponie a hledáte informace nebo vlastníte skleníky a jde vám o jeho maximální optimalizaci, neváhejte nás kontaktovat a sjednáme si schůzku. Víme, že je to kliše, ale myslíme to vážně, <StrongText>děláme to srdcem!💚</StrongText>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
