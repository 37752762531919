import React from 'react';
import PhotoOfMe from '../../img/Author.png';
import ContactForm from '../ContactForm/ContactForm';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <>
      <div className={styles.container} id="contactSection">
        <div className={styles.leftSide}>
          <div className={styles.superTextMedium}>
            Zůstaňme v <br />
            kontaktu
          </div>
        </div>
        <div className={styles.rightSide}>
          <img className={styles.imageSize} src={PhotoOfMe} alt="myPhoto" />
          <div className={styles.info}>
            <p>Zapálený informatik a zemědělec</p>
            <p>Bc. David Kopl</p>
            <p>KoplDavid@seznam.cz</p>
            <p>+420 774 786 742</p>
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default Footer;
