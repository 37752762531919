import React, { useState, useEffect } from 'react';
import CustomLink from './CustomLink';
import { handleScrollOnId } from '../../hooks/handleScrollOnId';
import { GiHamburgerMenu, GiCancel } from 'react-icons/gi';
import '../../../src/index.css';
import styles from './NavigationBar.module.css';

const NavigationBar = () => {
  const [menuStyle, setMenuStyle] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    // console.log(position);
    setScrollPosition(position);
    setMenuStyle(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const leftBarHandler = () => {
    setMenuStyle(!menuStyle);
    // if (!menuStyle) {
    // Prevent scrolling when isScrollDisabled is true
    // window.scrollTo(0, 0);
    // }
  };
  return (
    <>
      <nav className={scrollPosition > 20 ? `${styles.navbar} ${styles.sticky}` : styles.navbar}>
        <div className={styles.content}>
          <div className={styles.flex}>
            <h1 className={`${styles.logo} ${styles.unselectable}`}>SmartPush</h1>
            <h5 className={`${styles.quote} ${styles.unselectable}`}>cesta k přírodě...</h5>
          </div>
          <ul className={menuStyle ? styles.menuList : `${styles.menuList} ${styles.active}`}>
            <div className={`${styles.icon} ${styles.cancelBtn}`}>
              <i onClick={leftBarHandler} className={`${styles.fas} ${styles.faTimes}`}>
                <GiCancel />
              </i>
            </div>
            <CustomLink to="/" onClick={() => handleScrollOnId('homeSection')} className={styles.nav}>
              Domů
            </CustomLink>

            <CustomLink className={styles.nav} to="/blog">
              Blog
            </CustomLink>

            {/* <li>
              <CustomLink className={styles.nav} to="/pricing">
                Ceník
              </CustomLink>
             */}
            <CustomLink to="/" onClick={() => handleScrollOnId('serviceSection')} className={styles.nav}>
              Služby
            </CustomLink>

            <CustomLink to="/" onClick={() => handleScrollOnId('contactSection')} className={styles.nav}>
              Kontakt
            </CustomLink>
          </ul>
          <div onClick={leftBarHandler} className={menuStyle ? `${styles.icon} ${styles.menuBtn}` : ` ${styles.hide} ${styles.icon} ${styles.menuBtn}`}>
            <i className={`${styles.fas} ${styles.faBars}`}>
              <GiHamburgerMenu />
            </i>
          </div>
        </div>
      </nav>
      {/* <img src={backgroundPhoto} alt="Hydroponie background" className={styles.banner} id="homeSection"></img> */}
    </>
  );
};

export default NavigationBar;
