import React from 'react';
import MainLayout from '../../components/NavigationBar/MainLayout';
import Hero from './container/Hero';
// import Articles from '../blog/container/Articles';
const HomePage = () => {
  return (
    <MainLayout>
      <Hero />
      {/* <Articles /> */}
    </MainLayout>
  );
};

export default HomePage;
