import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../pages/blog/container/Header';
import { Breadcrumbs } from '../hooks/breadcrumbs';

const Layout = () => {
  return (
    <main>
      <Header />
      <Breadcrumbs />
      <Outlet />
    </main>
  );
};

export default Layout;
