import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Navigate, useParams } from 'react-router-dom';
import Editor from '../components/Editor';

const EditPage = () => {
  const { urlTitle } = useParams();
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [redirect, setRedirect] = useState('');
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const { setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKENDURL}/profile`, {
      credentials: 'include',
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, [setUserInfo]);

  const username = userInfo?.username;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKENDURL}/post/${urlTitle}`).then((response) => {
      response.json().then((postInfo) => {
        setTitle(postInfo.title);
        setContent(postInfo.content);
        setSummary(postInfo.summary);
      });
    });
  }, [urlTitle]);

  const updatePost = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.set('title', title);
    data.set('content', content);
    data.set('summary', summary);
    data.set('urlTitle', urlTitle);
    if (files?.[0]) {
      data.set('file', files?.[0]);
    }
    const response = await fetch(`${process.env.REACT_APP_BACKENDURL}/post`, {
      method: 'PUT',
      body: data,
      credentials: 'include',
    });
    const json = await response.json();
    if (response.ok) {
      setEmptyFields([]);
      setError(null);
      setRedirect(true);
    } else {
      setError(json.error);
      setEmptyFields(json.emptyFields ? Object.values(json.emptyFields).join(', ') : []);
      // setEmptyFields(Object.values(json.emptyFields).join(', '));
    }
  };
  if (redirect && username) {
    return <Navigate to={'/blog'} />;
  }

  return username ? (
    <form onSubmit={updatePost}>
      <input type="title" placeholder={'Title'} value={title} style={emptyFields.includes('title') ? { borderColor: 'red' } : {}} onChange={(e) => setTitle(e.target.value)} />
      <input type="summary" placeholder="Summary" value={summary} style={emptyFields.includes('summary') ? { borderColor: 'red' } : {}} onChange={(e) => setSummary(e.target.value)} />
      <input type="file" style={emptyFields.includes('file') ? { borderColor: 'red' } : {}} onChange={(e) => setFiles(e.target.files)} />
      <Editor onChange={setContent} value={content} style={emptyFields.includes('content') ? { backgroundColor: 'rgb(230,130,130)' } : {}} />
      {error && (
        <div style={{ color: 'red', fontSize: '2rem' }}>
          {error}: {emptyFields}
        </div>
      )}
      <button style={{ marginTop: '5px' }}>Update post</button>
    </form>
  ) : (
    <div>K editování je potřeba se prvně přihlásit ❤️ /login</div>
  );
};

export default EditPage;
